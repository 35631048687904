.fc {
    font-family: 'Roboto';
}

.fc-button-primary {
    background-color: rgb(161, 157, 255) !important;

}

.fc-day-today {
    background-color: rgb(161, 157, 255) !important;
}